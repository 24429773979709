import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { IDraw } from '@app/core/interfaces/i-draw';
import { Store } from '@ngrx/store';
import {
  selectAvailableDraws,
  selectCurrentDraw,
  selectLastPlayedDraws
} from '@app/store/selectors/draws.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { TIMEZONE_OFFSET, TRAN_DELAY } from '@app/core/utils';

/**
 * Компонент, содержащий все столы игры П4
 */
@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit, OnDestroy {
  /**
   * Наблюдаемая переменная с доступными тиражами
   */
  availableDraws$: Observable<Array<IDraw>>;

  /**
   * Последние разыгранные тиражи
   */
  lastPlayedDraws: Array<IDraw> = [];

  /**
   * Наблюдаемая переменная с последними разыгранными тиражами
   */
  lastPlayedDraws$: Observable<Array<IDraw>>;

  /**
   * Наблюдаемая переменная с текущим тиражом
   */
  currentDraw$: Observable<IDraw | undefined> = of(undefined);

  /**
   * Минимальная ставка (в грн)
   */
  minBet = 1;

  /**
   * Максимальная ставка (в грн)
   */
  maxBet = 1000;

  /**
   * Максимальное количество тиражей, доступных для регистрации ставки
   */
  maxDraws = 1;

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Смещение для временной зоны (киевское время)
   */
  timeZoneOffset = TIMEZONE_OFFSET;

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   */
  constructor(
    private readonly store: Store
  ) {
    this.availableDraws$ = store.select(selectAvailableDraws);
    this.lastPlayedDraws$ = store.select(selectLastPlayedDraws);
    this.currentDraw$ = store.select(selectCurrentDraw);
  }

  /**
   * Обработчик, вызываемый при получении доступных тиражей из наблюдаемой переменной
   * @param draws Массив доступных тиражей
   * @private
   */
  private onDrawsAvailable(draws: Array<IDraw>): void {
    this.minBet = Math.max(...draws.map((draw: IDraw) => draw.betData.min_bet));
    this.maxBet = Math.min(...draws.map((draw: IDraw) => draw.betData.max_bet));
    this.maxDraws = draws.length;
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.availableDraws$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(this.onDrawsAvailable.bind(this));

    this.lastPlayedDraws$.pipe(
      map((lpDraws: Array<IDraw>) => lpDraws
        .filter((lpDraw: IDraw) => {
          const gameEndTS = (new Date(lpDraw.game_end)).getTime();
          return Date.now() - gameEndTS > TRAN_DELAY;
        })
        .slice(0, 3)),
      takeUntil(this.destroy$)
    ).subscribe((lpDraws: Array<IDraw>) => {
      this.lastPlayedDraws = lpDraws;
    });
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
