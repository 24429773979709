<main class="page-main" data-cy="page-main">
  <router-outlet></router-outlet>
</main>

<aside class="page-right" data-cy="page-right">
  <app-draw-info class="draw-info" data-cy="draw-info"
                 [currentDraw]="(currentDraw$ | async) || undefined"
  ></app-draw-info>

  <app-bets-info class="bets-info" data-cy="bets-info"></app-bets-info>

  <app-caption *ngIf="router.url !== '/stats'"
               class="header-like"
               data-cy="header-like"
               [title]="'stats.statistics' | translate"
               routerLink="/stats"
  ></app-caption>

  <div class="links" data-cy="links">
    <a href="/cabinet/{{ translateService.currentLang || translateService.defaultLang }}/static/peremozna4_umovy"
       target="_blank"
       class="links__item links__item_1"
       data-cy="links-item"
    >
      <app-caption class="links__block links__block_cond"
                   data-cy="links-block-cond"
                   [title]="'conditions' | translate"
                   [corners]="true"
                   [cornersTablet]="true"
                   [cornersMobile]="true"
                   [effects]="true"
                   [padding]="'12px'"
      ></app-caption>
    </a>
    <a href="/{{ translateService.currentLang || translateService.defaultLang }}/static/peremozna4_rules"
       target="_blank"
       class="links__item links__item_2"
       data-cy="links-item"
    >
      <app-caption class="links__block"
                   data-cy="links-block-rules"
                   [title]="'rules' | translate"
                   [corners]="true"
                   [cornersTablet]="true"
                   [cornersMobile]="true"
                   [effects]="true"
      ></app-caption>
    </a>
  </div>

  <div data-cy="note" class="note">{{ 'check-ticket-on' | translate }} <a target="_blank" href="https://win.msl.com.ua/" class="note__link" data-cy="note-link">win.msl.com.ua</a></div>
</aside>

<app-game-menu appFixedInFrame [bottom]="'0px'" class="game-menu"></app-game-menu>
