<app-draw-info class="draw-info" [currentDraw]="(currentDraw$ | async) || undefined"></app-draw-info>

<div class="page-block">

  <div class="top-block">
    <div class="top-block__video" data-cy="top-block-video"></div>
    <div class="top-block__results last-results" data-cy="top-block-results">
      <app-caption class="last-results__title"
                   data-cy="last-results-title"
                   [title]="'pik4.last-draws-results' | translate"
                   [cornerColor]="'#FFFFFF'"
      ></app-caption>
      <div class="last-results__body" data-cy="last-results-body">

        <div class="last-results__inner" data-cy="last-results-inner">
          <div *ngFor="let draw of lastPlayedDraws" class="last-results__item" data-cy="last-results-item">
            <div class="last-results__draw">{{ draw.game_start | date:'HH:mm':'+0'+timeZoneOffset+'00' }} №{{ draw.number }}</div>
            <div *ngIf="draw.comb" class="last-results__balls">
              <div *ngFor="let ball of (draw.comb | comb)"
                   class="last-results__ball last-results__ball_{{ ball.color }}"
              >{{ ball.num }}</div>
            </div>
          </div>
        </div>

        <app-freaky-button class="last-results__archive"
                           data-cy="last-results-archive"
                           [title]="'pik4.results-archive' | translate"
                           routerLink="/results"
        ></app-freaky-button>
      </div>
    </div>
  </div>

  <app-table class="tables-block tables-block_table_2"
             data-cy="table"
             [table]="2"
             [title]="'pik4.color-and-count'"
             [hint]="'pik4.select-color-and-count'"
             [helpAlias]="'pick4_number_of_colors'"
             [minBet]="minBet"
             [maxBet]="maxBet"
             [maxDraws]="maxDraws"
  ></app-table>

  <app-table class="tables-block tables-block_table_1"
             data-cy="table"
             [table]="1"
             [title]="'pik4.numbers'"
             [hint]="'pik4.select-numbers'"
             [helpAlias]="'pick4_number'"
             [minBet]="minBet"
             [maxBet]="maxBet"
             [maxDraws]="maxDraws"
  ></app-table>

  <app-table class="tables-block tables-block_table_3"
             data-cy="table"
             [table]="3"
             [title]="'pik4.color-and-position'"
             [hint]="'pik4.select-color-and-position'"
             [helpAlias]="'pick4_color_in_position'"
             [minBet]="minBet"
             [maxBet]="maxBet"
             [maxDraws]="maxDraws"
  ></app-table>


</div>
