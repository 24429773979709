import {
  Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { IArchiveDraws } from '@app/core/interfaces/i-archive-draws';
import { Store } from '@ngrx/store';
import { selectFullArchive } from '@app/store/selectors/draws-archive.selectors';
import { takeUntil } from 'rxjs/operators';
import { IArchiveDraw } from '@app/core/interfaces/i-archive-draw';
import { Actions, ofType } from '@ngrx/effects';
import { APP_CONFIG, TIMEZONE_OFFSET, TRAN_DELAY } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';
import { ApiService } from '@app/core/services/api.service';

/**
 * Интерфейс для вывода результатов в шаблон компонента
 */
interface IResultItem {
  /**
   * Время тиража
   */
  time: Date;
  /**
   * Номер тиража
   */
  draw: string;
  /**
   * Выпавшие шары
   */
  balls: Array<{num: number; color: string}>;
}

/**
 * Компонент результатов тиражей
 */
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, OnDestroy {
  /**
   * Временное смещение для киевской временной зоны
   */
  readonly timeZoneOffset = TIMEZONE_OFFSET;

  /**
   * Переменная, выводящая в событие клик по кнопке "Назад"
   */
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Показывать по {@link #showBy} дней
   */
  showBy = 6;

  /**
   * Выбранная страница
   */
  selectedPage = 1;

  /**
   * Страниц в группе (видимой части листалки)
   */
  pagesInGroup = 6;

  /**
   * Выбранная группа
   */
  selectedGroup = 1;

  /**
   * Страницы
   */
  pages = new Array(this.pagesInGroup).fill(0);

  /**
   * Даты, за которые выводятся тиражи
   */
  dates = new Array(this.showBy).fill(Date.now());

  /**
   * Выбранная дата
   */
  selectedDate: number;

  /**
   * Часы, за которые выводятся тиражи
   */
  hours = new Array(24).fill(0);

  /**
   * Выбранный час
   */
  selectedHour = 0;

  /**
   * Всего групп страниц (интервалов в листалке)
   */
  maxGroups = 1000;

  /**
   * Наблюдаемая переменная для хранения архива тиражей
   */
  drawsArchive$: Observable<IArchiveDraws>;

  /**
   * Переменная для хранения архива тиражей
   */
  drawsArchive: IArchiveDraws = {};

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Грузятся ли сейчас архивные тиражи?
   */
  isLoading = false;

  /**
   * Выбранные тиражи за какой-либо день
   */
  selectedSlice: Array<IResultItem> = [];

  /**
   * Открыт ли выпадающий список со страницами
   */
  dropDownOpened = false;

  /**
   * Обработчик нажатия по DOM-документу
   * @param event
   */
  @HostListener('document:click', ['$event']) documentClick(event: Event) {
    const elem = event.target as HTMLElement;
    if (!elem.closest('.dropdown')) {
      this.dropDownOpened = false;
    }
  }

  /**
   * Конструктор компонента
   * @param translateService Сервис переводов
   * @param store NgRx-хранилище приложения
   * @param getDrawArchive$ Наблюдаемая переменная действий при получении архива тиражей
   * @param apiService API-сервис
   * @param config Объект конфигурации приложения
   */
  constructor(
              readonly translateService: TranslateService,
              private readonly store: Store,
              private readonly getDrawArchive$: Actions,
              private readonly apiService: ApiService,
              @Inject(APP_CONFIG) private readonly config: IConfig
  ) {
    this.updateMaxGroups();
    this.updatePages();
    this.updateDates();
    [this.selectedDate] = this.dates;
    this.selectDate();
    this.drawsArchive$ = store.select(selectFullArchive);
    getDrawArchive$.pipe(
      ofType('[Draws Archive] Get Draws Archive Success'),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.isLoading = false;
      this.showResults();
    });
  }

  /**
   * Обработчик клика по кнопке открытия выпадающего списка
   */
  dropDownOpen(): void {
    this.dropDownOpened = true;
  }

  /**
   * Показать по showBy дат результатов
   */
  doShowBy(): void {
    this.dropDownOpened = false;
    this.updateMaxGroups();
    this.updatePageByDate();
  }

  /**
   * Перелистнуть на следующую группу номеров страниц
   */
  nextPageGroup(): void {
    if (this.selectedGroup < this.maxGroups) {
      this.selectedGroup += 1;
      this.updatePages();
    }
  }

  /**
   * Перелистнуть на предыдущую группу номеров страниц
   */
  prevPageGroup(): void {
    if (this.selectedGroup > 1) {
      this.selectedGroup -= 1;
      this.updatePages();
    }
  }

  /**
   * Обработчик выбора страницы
   */
  onSelectPage(): void {
    this.updateDates();
  }

  /**
   * Обработчик выбора даты
   */
  selectDate(): void {
    this.updateHours();
    // если текущая дата - последний час
    const hoursInDay = 24 * 60 * 60 * 1000;
    if (Math.floor(this.selectedDate / hoursInDay) === Math.floor(Date.now() / hoursInDay)) {
      this.selectedHour = this.hours[this.hours.length - 1];
    } else {
      [this.selectedHour] = this.hours;
    }
    this.showResults();
  }

  /**
   * Обработчик выбора времени
   */
  showResults(): void {
    const selectedDateObj = new Date(this.selectedDate);
    selectedDateObj.setUTCHours(this.selectedHour - TIMEZONE_OFFSET, 0, 0);
    const dateStart = selectedDateObj.toISOString();
    if (
      !this.drawsArchive[30]
        || !this.drawsArchive[30][dateStart]
        || !this.drawsArchive[30][dateStart].length
    ) {
      this.isLoading = true;
      this.store.dispatch({ type: '[Draws Archive] Get Draws Archive', lottCode: 30, dateStart });
    } else {
      this.selectedSlice = this.convertForOutput(this.drawsArchive[30][dateStart]);
    }
  }

  /**
   * Метод для преобразования массива архивных тиражей в удобный вид для вывода в шаблоне
   * @param draws
   */
  convertForOutput(draws: Array<IArchiveDraw>): Array<IResultItem> {
    return draws.map(
      (aDraw: IArchiveDraw) => {
        const ballsArr = aDraw.comb.split(',');
        const balls = ballsArr.map((code: string) => ({
          num: +code.substring(1),
          color: code.substring(0, 1).toLowerCase()
        }));
        return {
          time: new Date(aDraw.game_end),
          draw: aDraw.number,
          balls
        };
      }
    )
      .filter(
        (item: IResultItem) => Date.now()
          - item.time.getTime() - this.apiService.playingDuration > TRAN_DELAY
      );
  }

  /**
   * Обновить количество групп страниц (интервалов в листалке)
   * @private
   */
  private updateMaxGroups(): void {
    const daysNow = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
    const lotteryStart = (new Date(this.config.startDates[30])).getTime();
    const daysStart = Math.floor(lotteryStart / (24 * 60 * 60 * 1000));
    this.maxGroups = Math.ceil((daysNow - daysStart + 1) / (this.pagesInGroup * this.showBy));
  }

  /**
   * Получить количество дней с даты старта лотереи
   * @private
   */
  private getTotalDays(): number {
    const daysNow = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
    const lotteryStart = (new Date(this.config.startDates[30])).getTime();
    const daysStart = Math.floor(lotteryStart / (24 * 60 * 60 * 1000));
    return daysNow - daysStart + 1;
  }

  /**
   * Получить количество страниц
   * @private
   */
  private getTotalPages(): number {
    return Math.ceil(this.getTotalDays() / this.showBy);
  }

  /**
   * Обновить номера страниц в видимой части листалки
   * @private
   */
  private updatePages(): void {
    const totalPages = this.getTotalPages();
    const pagesInGroup = this.selectedGroup === this.maxGroups
      ? ((totalPages - 1) % this.pagesInGroup) + 1 : this.pagesInGroup;
    this.pages = new Array(pagesInGroup)
      .fill(0)
      .map((v, i) => (this.selectedGroup - 1) * this.pagesInGroup + i + 1);
  }

  /**
   * Обновить даты, которые можно выбрать для показа тиражей
   * @private
   */
  private updateDates(): void {
    const totalDays = this.getTotalDays();
    const totalPages = this.getTotalPages();
    const datesToShow = this.selectedPage === totalPages
      ? ((totalDays - 1) % this.showBy) + 1 : this.showBy;
    this.dates = (new Array(datesToShow))
      .fill(Date.now())
      .map((v, i) => {
        const dateNow = Date.now();
        return dateNow - (dateNow % (60 * 60 * 1000))
          - ((this.selectedPage - 1) * this.showBy + i) * 24 * 60 * 60 * 1000;
      });
  }

  /**
   * Обновить выбранную страницу и группу страниц при выборе даты показа результатов тиражей
   */
  private updatePageByDate(): void {
    const positionFromNow = Math.floor((Date.now() - this.selectedDate) / (1000 * 60 * 60 * 24));
    this.selectedPage = Math.floor(positionFromNow / this.showBy) + 1;
    this.selectedGroup = Math.floor((this.selectedPage - 1) / this.pagesInGroup) + 1;
    this.updatePages();
    this.updateDates();
  }

  /**
   * Обновить часы вывода тиражей на соответствующих кнопках
   * @private
   */
  private updateHours(): void {
    const currDate = new Date();
    const currY = currDate.getFullYear();
    const currM = currDate.getMonth();
    const currD = currDate.getDate();
    const minDate = new Date(this.config.startDates[30]);
    const minY = minDate.getFullYear();
    const minM = minDate.getMonth();
    const minD = minDate.getDate();
    const selectedDateObj = new Date(this.selectedDate);
    let maxHour = 23;
    let minHour = 0;
    if (
      currY === selectedDateObj.getFullYear()
      && currM === selectedDateObj.getMonth()
      && currD === selectedDateObj.getDate()
    ) {
      maxHour = currDate.getUTCHours() + TIMEZONE_OFFSET;
    }
    if (
      minY === selectedDateObj.getFullYear()
      && minM === selectedDateObj.getMonth()
      && minD === selectedDateObj.getDate()
    ) {
      minHour = minDate.getUTCHours() + TIMEZONE_OFFSET;
    }

    this.hours = (new Array(maxHour - minHour + 1))
      .fill(0)
      .map((v, i) => i + minHour);
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.drawsArchive$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((drawsArchive: IArchiveDraws) => {
        this.drawsArchive = drawsArchive;
      });
    this.showResults();
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
