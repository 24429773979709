<app-caption class="results-title"
             data-cy="results-title"
             [title]="'results.last-draws-results' | translate"
             [cornerColor]="'#FFFFFF'"
             [corners]="true"
             [cornersTablet]="true"
             [padding]="'0 40px'"
>
  <button type="button" class="results-title__to-reg-bets" routerLink="/">
    <span class="results-title__back-to">
      {{ 'pik4.back-to-bets' | translate }}
    </span>
  </button>
</app-caption>

<div data-cy="draws-results" class="draws-results">
  <h3 data-cy="draws-results-date" class="draws-results__date">
    {{ selectedDate | date:'d MMMM YYYY':'+0'+timeZoneOffset+'00':translateService.currentLang === 'uk' ? 'uk-UA' : 'ru-RU' }}
    {{ 'results.of-year' | translate }}
  </h3>

  <div data-cy="draws-results-items" class="draws-results__items">
    <div data-cy="draws-results-items-inner" class="draws-results__items-inner">
      <ng-container *ngFor="let hour of hours">
        <input id="h{{ hour }}"
               name="draws_hour"
               type="radio"
               class="draws-results__control"
               data-cy="draws-results-control"
               [value]="hour"
               [(ngModel)]="selectedHour"
               (ngModelChange)="showResults()"
        />
        <label for="h{{ hour }}"
               class="draws-results__item" data-cy="draws-results-item"
        >{{ hour < 10 ? '0' + hour : hour }}:00</label>
      </ng-container>

    </div>
  </div>

  <div *ngIf="isLoading" class="draws-results__loading">
    <img width="177"
         height="20"
         src="./assets/img/loading.gif"
         alt="{{ 'loading' | translate }}"
         class="draws-results__preloader"
    >
  </div>

  <div *ngIf="!isLoading" class="draws-results__combs results-combs" data-cy="results-combs">
    <div class="results-combs__header" data-cy="results-combs-header">{{ 'results.time' | translate }}</div>
    <div class="results-combs__header" data-cy="results-combs-header">{{ 'results.draw' | translate }}</div>
    <div class="results-combs__header" data-cy="results-combs-header">{{ 'results.result' | translate }}</div>

    <ng-container *ngFor="let item of selectedSlice">
      <time data-cy="results-combs-time" class="results-combs__time">{{ item.time | date:'HH:mm':'+0'+timeZoneOffset+'00':translateService.currentLang === 'uk' ? 'uk-UA' : 'ru-RU'  }}</time>
      <div data-cy="results-combs-draw" class="results-combs__draw">№{{ item.draw }}</div>
      <div data-cy="results-combs-balls" class="results-combs__balls">
        <div *ngFor="let ball of item.balls"
             data-cy="results-combs-ball" class="results-combs__ball results-combs__ball_{{ ball.color }}"
        >{{ ball.num }}</div>
      </div>
    </ng-container>
  </div>

  <div data-cy="results-combs-dates" class="draws-results__dates">
    <div data-cy="results-combs-dates-inner" class="draws-results__dates-inner">
      <ng-container *ngFor="let date of dates">
        <input id="d_{{ date | date:'dd_MM_YYYY' }}"
               type="radio"
               name="draws_day"
               class="draws-results__control"
               data-cy="draws-results-control"
               [value]="date"
               [(ngModel)]="selectedDate"
               (ngModelChange)="selectDate()"
        />
        <label for="d_{{ date | date:'dd_MM_YYYY' }}"
               class="draws-results__day"
               data-cy="draws-results-day"
        >{{ date | date:'d MMMM':undefined:translateService.currentLang === 'uk' ? 'uk-UA' : 'ru-RU' }}</label>
      </ng-container>
    </div>
  </div>

  <div class="draws-results__pagination pagination"
       data-cy="draws-results-pagination"
  >
    <button *ngIf="selectedGroup > 1"
            type="button"
            class="pagination__button pagination__button_prev"
            data-cy="pagination-prev"
            (click)="prevPageGroup()"
    ></button>
    <ng-container *ngFor="let page of pages">
      <input id="page{{ page }}"
             name="page"
             type="radio"
             class="pagination__page"
             data-cy="pagination-page"
             [value]="page"
             [(ngModel)]="selectedPage"
             (ngModelChange)="onSelectPage()"
      />
      <label for="page{{ page }}"
             class="pagination__button"
             data-cy="pagination-button"
      >{{ page }}</label>
    </ng-container>
    <button *ngIf="selectedGroup < maxGroups"
            type="button"
            class="pagination__button pagination__button_next"
            data-cy="pagination-next"
            (click)="nextPageGroup()"
    ></button>
  </div>

  <div class="draws-results__by by"
       data-cy="draws-results-by"
  >
    {{ 'results.show-by' | translate }}:
    <div class="dropdown by__num"
         data-cy="dropdown"
         (click)="dropDownOpen()">
      <div class="dropdown__current"
           data-cy="dropdown-current"
           (click)="dropDownOpen()"
      >{{ showBy }}</div>
      <button type="button"
              class="dropdown__button"
              data-cy="dropdown-button"
              (click)="dropDownOpen()"
      ></button>
      <div *ngIf="dropDownOpened"
           class="dropdown__list"
           data-cy="dropdown-list"
      >
        <label class="dropdown__item"
               data-cy="dropdown-item"
               *ngFor="let by of [3, 6, 9]"
               [class.dropdown__item_active]="by === showBy"
        >
          <input type="radio"
                 class="dropdown__option"
                 name="show_by"
                 data-cy="dropdown-option"
                 [value]="by"
                 [(ngModel)]="showBy"
                 (ngModelChange)="doShowBy()"
          /> {{ by }}
        </label>
      </div>
    </div>
    {{ 'results.days' | translate }}
  </div>
</div>
